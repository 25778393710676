import * as React from 'react';

function IconFilledQuestionCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12 3a9 9 0 10.001 18.001A9 9 0 0012 3z" fill="#8F92A1" />
      <path
        d="M12 17c-.414 0-.75-.36-.75-.806 0-.446.336-.807.75-.807s.75.361.75.806c0 .446-.336.807-.75.807zm1.18-4.425a.92.92 0 00-.42.355c-.103.161-.159.352-.16.548v.457c0 .09-.067.162-.15.162h-.9c-.083 0-.15-.073-.15-.162v-.433c0-.466.126-.925.373-1.309.242-.374.58-.66.977-.824.637-.264 1.05-.839 1.05-1.466 0-.889-.808-1.613-1.8-1.613s-1.8.724-1.8 1.613v.153c0 .09-.067.162-.15.162h-.9c-.082 0-.15-.073-.15-.162v-.153c0-.792.322-1.532.908-2.082A3.033 3.033 0 0112 7c.787 0 1.53.292 2.092.82.585.551.908 1.29.908 2.083 0 1.165-.714 2.214-1.82 2.672z"
        fill="#fff"
      />
    </svg>
  );
}

const MemoIconFilledQuestionCircle = React.memo(IconFilledQuestionCircle);
export default MemoIconFilledQuestionCircle;
