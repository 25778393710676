import * as React from 'react';

function IconOther3dMonitor(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M21.004 13v1.43a2.572 2.572 0 01-2.572 2.572H5.568a2.572 2.572 0 01-2.572-2.572V5.57a2.572 2.572 0 012.572-2.572H11"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M17.506 10.707l-3.001-1.715a1 1 0 01-.504-.868V4.712a1 1 0 01.504-.87l3.001-1.714a1 1 0 01.993 0L21.5 3.843a1 1 0 01.504.869v3.412a1 1 0 01-.504.868L18.5 10.707a1 1 0 01-.993 0z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.138 4.21l3.865 2.208M21.868 4.208l-3.865 2.21M18.003 6.417v4.42M14 17.002l.501 4.002M10 17.002l-.5 4.002M8.142 21.004h7.717"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconOther3dMonitor = React.memo(IconOther3dMonitor);
export default MemoIconOther3dMonitor;
