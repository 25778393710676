import * as React from 'react';
import PropTypes from 'prop-types';

import OutlineArrowChevronRight from '@dolstaff/shared/es/icons/OutlineArrowChevronRight';
import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';

const Link = ({ title, url, containerStyle, typoStyle = {}, ...rest }) => {
  return (
    <Main style={containerStyle}>
      <a href={url} rel="noopener noreferrer" target="_blank">
        <Container
          whileTap={{ borderColor: colorsV2.primary100 }}
          whileHover={{ borderColor: colorsV2.primary100 }}
          {...rest}
        >
          <Typography variant="medium/14" style={typoStyle}>
            {title || url}
          </Typography>
          <OutlineArrowChevronRight />
        </Container>
      </a>
    </Main>
  );
};

const Main = styled.div`
  a {
    outline: none;
    appearance: none;
    color: inherit;
  }
  @media screen and ${deviceBreakpoints.mobile} {
    a {
      word-break: break-word;
    }
  }
`;

const Container = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 8px;
  align-items: center;
  padding: 10px 16px;
  background: ${colorsV2.gray5};
  border: 1px solid ${colorsV2.gray40};
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  appearance: none;
`;

Link.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string.isRequired
};

Link.defaultProps = {};

export default React.memo(Link);
