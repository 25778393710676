import * as React from 'react';
import styled from 'styled-components';
import { colorsV2 } from '../../style/colors-v2';

export const ThreeDotSeparatorWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  grid-auto-flow: column;
  grid-column-gap: 24px;
`;
const Dot = styled.div`
  background-color: ${colorsV2.gray100};
  width: 3px;
  height: 3px;
  border-radius: 50%;
`;
export const ThreeDotSeparator = () => {
  return (
    <ThreeDotSeparatorWrapper>
      <Dot />
      <Dot />
      <Dot />
    </ThreeDotSeparatorWrapper>
  );
};
