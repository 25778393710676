import { useDeviceDetect } from '../../utils/hooks/useDeviceQuery.hook';
import { useMounted } from '../../utils/hooks/useMounted';

export const useTriggerOnDeviceType = () => {
  const { isDesktop } = useDeviceDetect();

  const mounted = useMounted();

  if (mounted) {
    if (isDesktop) {
      return ['hover'];
    } else {
      return ['click'];
    }
  }

  return ['click'];
};
