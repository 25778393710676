import * as React from 'react';

function IconOtherHoc1Dc4(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M17.633 14.39v0a3.25 3.25 0 00-3.25 3.25v0a3.25 3.25 0 003.25 3.25v0a3.25 3.25 0 003.25-3.25v0a3.25 3.25 0 00-3.25-3.25zM16.631 3.616L14.147 7.59a1.182 1.182 0 001.002 1.808h4.97A1.182 1.182 0 0021.12 7.59l-2.484-3.975a1.182 1.182 0 00-2.005 0v0zM6.362 20.888v0a3.25 3.25 0 01-3.25-3.25v0a3.25 3.25 0 013.25-3.25v0a3.25 3.25 0 013.25 3.25v0a3.25 3.25 0 01-3.25 3.25zM6.432 3.112v0a3.25 3.25 0 00-3.25 3.25v0a3.25 3.25 0 003.25 3.25v0a3.25 3.25 0 003.25-3.25v0a3.25 3.25 0 00-3.25-3.25z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconOtherHoc1Dc4 = React.memo(IconOtherHoc1Dc4);
export default MemoIconOtherHoc1Dc4;
