import * as React from 'react';

function IconMessageHeart(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M9.3 17.286l1.836 2.448a1.08 1.08 0 001.728 0l1.835-2.448h3.06a2.88 2.88 0 002.88-2.88v-8.64a2.88 2.88 0 00-2.88-2.88H6.24a2.88 2.88 0 00-2.88 2.88v8.64a2.88 2.88 0 002.88 2.88H9.3z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M12 7.548a2.397 2.397 0 011.84-.822c1.58 0 2.64 1.43 2.64 2.762 0 2.784-3.594 4.918-4.48 4.918-.886 0-4.48-2.134-4.48-4.918 0-1.332 1.06-2.762 2.64-2.762.904 0 1.497.435 1.84.822z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconMessageHeart = React.memo(IconMessageHeart);
export default MemoIconMessageHeart;
