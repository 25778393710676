/* eslint-disable react/prop-types */
import * as React from 'react';

import styled from 'styled-components';

import BreadcrumbSectionHeader, {
  TextContainer
} from 'shared/BreadcrumbSectionHeader';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';

import { Typography } from '../components/DesignSystemV2/Typography';
import MainLayout from '../components/Layout/MainLayout';
import { withMarketingSection } from '../components/withMarketingSection';
import HeaderSchedulePage from '../shared/Header/HeaderSchedulePage';
import MySEO from '../shared/MySEO';
import { StickyScholarshipCTA } from '../shared/SchedulePage/ScholarshipCTA';
import SectionScholarshipComparisonTable from '../shared/SchedulePage/SectionScholarshipComparisonTable';
import SectionScholarshipGuaranteedOutput from '../shared/SchedulePage/SectionScholarshipGuaranteedOutput';
import { deviceBreakpoints } from '../utils/hooks/useDeviceQuery.hook';
import { fromScreen } from '../utils/media-query/responsive.util';
import { SeparationSectionLayout } from '../shared/Layout/SectionLayout';

const Main = styled.div`
  .page-content {
    padding-top: 32px;
  }
  @media ${deviceBreakpoints.mobile}, ${deviceBreakpoints.tablet} {
    .section-scholarship-cta-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      transform: translateY(-64px);
      margin-bottom: -40px;

      .scholarship-cta {
        width: calc(100% - 32px);
        box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.2);
      }
    }
  }
  @media ${deviceBreakpoints.mobile} {
    .section-scholarship-cta-mobile {
      .scholarship-cta {
        max-width: calc(428px - 32px);
      }
    }
  }
  @media ${deviceBreakpoints.tablet} {
    .section-scholarship-cta-mobile {
      .scholarship-cta {
        width: calc(768px - 32px);
      }
    }
  }

  ${fromScreen(1366)} {
    ${TextContainer} {
      width: 771px;
    }
    .comparison-table-sticky-cta {
      max-width: 1120px;
      margin: 0 auto;
    }
  }
`;
const ContentSection = withMarketingSection(
  () => {
    return (
      <div className="page-content">
        <div className="with-right-cta">
          <SeparationSectionLayout lastChildHasSeparator>
            <SectionScholarshipGuaranteedOutput />
            <SectionScholarshipComparisonTable />
          </SeparationSectionLayout>
        </div>

        <div id="end-of-sticky-1" />
      </div>
    );
  },
  {
    style: {
      overflow: 'visible',
      paddingTop: 0,
      paddingBottom: 0
    },
    selfish: true
  }
);
const SchedulePage = ({ location }) => {
  return (
    <MainLayout
      renderCustomHeader={() => <HeaderSchedulePage />}
      noHeader
      hideSideCTA
    >
      <MySEO
        slug={location.pathname}
        h1="Đảm bảo đầu ra"
        hidePostfix
        title="Đảm bảo đầu ra - DOL English"
        description="Việc đảm bảo đầu ra của DOL rất khác biệt. Qua bài viết, DOL muốn định nghĩa lại thế nào là đảm bảo đầu ra, các yếu tố giúp học viên đạt đầu ra mong đợi là gì."
      />
      <FixedTopBarScholarshipScheduleNOffline />

      <Main className="dam-bao-dau-ra-page">
        <StickyScholarshipCTA
          className="comparison-table-sticky-cta desktop-only"
          targetQuery="#end-of-sticky-1"
          ctaQuery=".comparison-table-sticky-cta .scholarship-cta"
          visible
        />
        <BreadcrumbSectionHeader
          id="teachers"
          breadcrumbLinks={[
            {
              text: 'IELTS Courses',
              to: '/'
            },
            {
              text: 'Đảm bảo đầu ra',
              to: '/dam-bao-dau-ra'
            }
          ]}
          title="Đảm bảo đầu ra"
          description={
            <Typography variant="regular/16">
              <span className="text">
                Chương trình đảm bảo đầu ra của DOL cũng rất khác biệt. Thông
                qua bài viết này, DOL muốn tái định nghĩa lại thế nào là đảm bảo
                đầu ra, các yếu tố giúp 1 học viên đạt đầu ra mong đợi là gì.
              </span>
            </Typography>
          }
        />

        <ContentSection />
      </Main>
    </MainLayout>
  );
};

export default SchedulePage;
