/* eslint-disable react/prop-types */
import * as React from 'react';
import Timer from 'react-compound-timer';
import Sticky from 'react-stickynode';
import Popup from 'reactjs-popup';

import GlyphInterfaceIcCInfo from '@dolstaff/shared/es/icons/GlyphInterfaceIcCInfo';
import cl from 'classnames';
import { motion } from 'framer-motion';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Button from 'components/Button';
import { useTriggerOnDeviceType } from 'components/Popup/useTriggerOnDeviceType';
import { colorsV2 } from 'style/colors-v2';
import { formatNumberToVndCurrency } from 'utils/currency';

import Certificates from '../CourseLanding/Certificates';
import { countRemainPromotion } from '../CourseLanding/Detail/countRemainPromotion';
import IconInterfaceStarFavorite from './icons/IconInterfaceStarFavorite';
import IconLaptopMobile from './icons/IconLaptopMobile';
import IconLightBulb from './icons/IconLightBulb';
import IconMessageHeart from './icons/IconMessageHeart';
import IconOther3dMonitor from './icons/IconOther3dMonitor';
import IconOtherHoc1Dc4 from './icons/IconOtherHoc1Dc4';
import IconAlarmClockTime from './icons/IconAlarmClockTime';
import DolInfo from '../DolInfo';
import { Typography } from '../../components/DesignSystemV2/Typography';
import { useMounted } from 'utils/hooks/useMounted';
import { UserPixelTracking } from 'utils/userPixelTracking';

const StyledPopup = styled(Popup)`
  &-content {
    width: fit-content !important;
    .popup-arrow {
      color: ${colorsV2.black100};
    }
  }
`;

const Main = styled.div`
  border-radius: 16px;
  background: #ffffff;
  width: 317px;
  padding: 24px;
  box-shadow: 0px 0px 20px 10px rgba(227, 230, 236, 1);

  .scholarship-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .scholarship-header-icon {
      margin-right: 6px;
      font-size: 20px;
      line-height: 0;
    }
    .scholarship-header-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      color: ${colorsV2.black60};
    }
  }
  .scholarship-bonus {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0px;
  }
  .countdown-section {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    color: ${colorsV2.primary100};
    margin-bottom: 12px;
    .countdown-text {
      margin-left: 8px;
    }
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .six-different-point {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0px;
    margin-bottom: 8px;
  }
  .bullet-list {
    .bullet-list-item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      position: relative;
      z-index: 1;
      &:last-child {
        margin-bottom: 0;
      }
      .bullet-list-item-icon {
        font-size: 24px;
        line-height: 0;
        margin-right: 16px;
        color: ${colorsV2.black80};
      }
      .bullet-list-item-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0px;
        color: ${colorsV2.black80};
      }
      &.tooltip-link {
        color: ${colorsV2.primary100};
        cursor: pointer;
        padding: 40px 26px;
        margin: -40px -26px -24px -26px;
        outline: none;
        -webkit-tap-highlight-color: transparent;
        position: relative;
        z-index: 2;
        .bullet-list-item-icon {
          color: ${colorsV2.primary100};
        }
        .bullet-list-item-text {
          color: ${colorsV2.primary100};
          text-decoration-color: ${colorsV2.primary100};
        }
      }
      .tooltip-holder {
        display: flex;
        align-items: center;

        .btn-round-icon {
          line-height: 0;
        }
      }
    }
  }

  .cta-section {
    margin-bottom: 24px;
    .description {
      color: ${colorsV2.black80};
      text-align: center;
    }
  }
  .btn-cta {
    margin-bottom: 10px;
    button {
      font-family: 'Inter';
      width: 100%;
      border-radius: 8px;
      background-color: ${colorsV2.primary100};
      font-weight: 600;
    }
    &.outline {
      button {
        background-color: ${colorsV2.white100};
        border: 1px solid ${colorsV2.black100};
        color: ${colorsV2.black100};
      }
    }
  }
`;

const allIcons = [
  IconLightBulb,
  IconOtherHoc1Dc4,
  IconInterfaceStarFavorite,
  IconMessageHeart,
  IconOther3dMonitor,
  IconLaptopMobile
];

const ScholarshipCTA = ({ data }) => {
  const trigger = useTriggerOnDeviceType();

  const { remainQuantity, value, startDate, endDate } =
    data.allGoogleSpreadsheetDolLandingPageCmPromotion.edges[0]?.node || {};
  const { remainSlot } = countRemainPromotion({
    remainQuantity,
    startDate,
    endDate
  });
  const { differences, heading } = data.gcms.jsonContanier.content;
  const initialTime = Math.max(
    new Date(endDate).getTime() - new Date().getTime(),
    0
  );

  const isPromotionEmpty = remainSlot === 0 || initialTime === 0;

  const onClick = () => {
    UserPixelTracking.clickEvent();
  };

  return (
    <Main className="scholarship-cta">
      {!isPromotionEmpty && (
        <>
          <div className="scholarship-bonus">
            Ưu đãi lên đến {formatNumberToVndCurrency(value)}
          </div>
          <div className="countdown-section">
            <IconAlarmClockTime />
            <div className="countdown-text">
              <Timer initialTime={initialTime} direction="backward">
                {() => (
                  <>
                    Kết thúc sau{' '}
                    <span className="count-down">
                      <Timer.Days />d <Timer.Hours />h <Timer.Minutes />m{' '}
                      <Timer.Seconds />s
                    </span>
                  </>
                )}
              </Timer>
            </div>
          </div>
        </>
      )}

      <div className="cta-section">
        <DolInfo>
          {({ dolInfo }) => (
            <div className="btn-cta fb-message">
              <Link
                target="_blank"
                href={dolInfo.messenger.href}
                rel="noreferrer noopener"
              >
                <Button onClick={onClick}>Nhắn tin DOL qua FB</Button>
              </Link>
            </div>
          )}
        </DolInfo>
        <div className="description">
          <Typography type="span" className="text" variant="regular/12-20">
            *Đảm bảo đầu ra
          </Typography>
        </div>
      </div>

      <div
        className={cl('six-different-point', {
          'empty-promotion': isPromotionEmpty
        })}
      >
        {heading.emphasize}
      </div>
      <div className="bullet-list">
        {differences.map((diffItem, index) => {
          const { title, order, info } = diffItem;
          const isPopUp = info && info.type === 'popup';
          const Icon = allIcons[index];
          if (isPopUp) {
            return (
              <StyledPopup
                key={title + order}
                repositionOnResize
                on={trigger}
                offsetY={-40}
                trigger={({ toggleContent }) => (
                  <div className="bullet-list-item tooltip-link">
                    <div className="bullet-list-item-icon">
                      <IconLightBulb />
                    </div>
                    <div className="bullet-list-item-text">
                      <div className="tooltip-holder">
                        {title}{' '}
                        <div onClick={toggleContent} className="btn-round-icon">
                          <GlyphInterfaceIcCInfo
                            width="17px"
                            height="17px"
                            color={colorsV2.black40}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              >
                <Certificates data={info.content} />
              </StyledPopup>
            );
          }
          return (
            <div key={title + order} className="bullet-list-item">
              <div className="bullet-list-item-icon">
                <Icon />
              </div>
              <div className="bullet-list-item-text">{title}</div>
            </div>
          );
        })}
      </div>
    </Main>
  );
};

const StickyWrapper = styled(motion.div)`
  .scholarship-cta {
    top: 45px;
    position: absolute;
    right: 0;
  }
`;

const StickyScholarshipUI = ({
  data,
  targetQuery,
  ctaQuery,
  effectDependency,
  visible = true,
  className
}) => {
  const [bottomBoundary, setBottomBoundary] = React.useState(0);

  React.useEffect(() => {
    const targetEl = document.querySelector(targetQuery);
    const ctaEl = document.querySelector(ctaQuery);
    if (targetEl && ctaEl) {
      setTimeout(() => {
        const y =
          document.documentElement.scrollTop +
          targetEl.getBoundingClientRect().bottom -
          (ctaEl.clientHeight + 74);
        setBottomBoundary(y);
      }, 400);
    }
  }, [effectDependency, ctaQuery, targetQuery]);
  return (
    <Sticky
      className={className}
      top={112}
      innerZ={10}
      bottomBoundary={bottomBoundary}
    >
      <StickyWrapper animate={{ opacity: visible ? 1 : 0 }}>
        <ScholarshipCTA data={data} />
      </StickyWrapper>
    </Sticky>
  );
};

const useScholarshipData = () => {
  return useStaticQuery(graphql`
    query DifferencesQuery {
      gcms {
        jsonContanier(where: { name: "LandingCourse_Differences" }) {
          content
        }
      }
      allGoogleSpreadsheetDolLandingPageCmPromotion(
        filter: { status: { eq: "on" } }
      ) {
        edges {
          node {
            id
            remainQuantity
            displayName
            startDate
            endDate
            value
            interestQuantity
          }
        }
      }
    }
  `);
};

export const StickyScholarshipCTA = props => {
  const data = useScholarshipData();
  const isMounted = useMounted();
  if (!isMounted) return null;
  return <StickyScholarshipUI {...props} data={data} />;
};

const MainScholarshipCTA = () => {
  const data = useScholarshipData();
  return <ScholarshipCTA data={data} />;
};

export default MainScholarshipCTA;
