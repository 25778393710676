import * as React from 'react';

function IconCodeHashTag(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 4v4H4v2h4v4H4v2h4v4h2v-4h4v4h2v-4h4v-2h-4v-4h4V8h-4V4h-2v4h-4V4H8zm6 10v-4h-4v4h4z"
        fill="#242938"
      />
    </svg>
  );
}

const MemoIconCodeHashTag = React.memo(IconCodeHashTag);
export default MemoIconCodeHashTag;
