import * as React from 'react';

import styled from 'styled-components';

import { colorsV2 } from '../../style/colors-v2';
import IconFilledDoubleQuote from './icons/IconFilledDoubleQuote';

const Main = styled.div`
  border-left: 4px solid ${colorsV2.black100};
  padding-left: 32px;
  .double-quote-icon {
    font-size: 32px;
  }
  .quote-text {
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
  }
`;

const QuoteText = () => {
  return (
    <Main>
      <div className="double-quote-icon">
        <IconFilledDoubleQuote />
      </div>
      <div className="quote-text font-secondary">
        DOL tin tưởng với Linearthinking và trải nghiệm học tập ở DOL, các học
        viên học tập nghiêm túc chắc chắn sẽ đạt đầu ra.
      </div>
    </Main>
  );
};

export default QuoteText;
