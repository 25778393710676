import * as React from 'react';

function IconLaptopMobile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20 10.5V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10M15 18H3a1 1 0 01-1-1v-1a1 1 0 011-1h12"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        clipRule="evenodd"
        d="M21 21h-5a1 1 0 01-1-1v-8.5a1 1 0 011-1h5a1 1 0 011 1V20a1 1 0 01-1 1z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 18h7"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconLaptopMobile = React.memo(IconLaptopMobile);
export default MemoIconLaptopMobile;
