import * as React from 'react';

function IconFilledDoubleQuote(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" {...props}>
      <path
        d="M21.512 24c-1.302 0-2.457-.443-3.465-1.328-.966-.928-1.449-2.215-1.449-3.86 0-2.867.924-5.398 2.772-7.59 1.848-2.236 4.724-3.754 8.63-4.555l-.252 2.657c-4.283.97-6.425 2.741-6.425 5.314 0 .548.21 1.012.63 1.391.42.338.882.675 1.386 1.012.546.338 1.029.76 1.448 1.266.42.464.63 1.117.63 1.96 0 1.224-.378 2.152-1.134 2.784-.755.633-1.68.95-2.771.95zM8.913 24c-1.301 0-2.456-.443-3.464-1.328C4.483 21.744 4 20.457 4 18.812c0-2.867.924-5.398 2.772-7.59 1.847-2.236 4.724-3.754 8.63-4.555l-.252 2.657c-4.284.97-6.426 2.741-6.426 5.314 0 .548.21 1.012.63 1.391.42.338.882.675 1.386 1.012.546.338 1.029.76 1.449 1.266.42.464.63 1.117.63 1.96 0 1.224-.378 2.152-1.134 2.784-.756.633-1.68.95-2.772.95z"
        fill="#8F92A1"
      />
    </svg>
  );
}

const MemoIconFilledDoubleQuote = React.memo(IconFilledDoubleQuote);
export default MemoIconFilledDoubleQuote;
