import * as React from 'react';

function IconUserProfile(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.4 16.6A3.375 3.375 0 006 20a1 1 0 11-2 0c0-3.067 2.463-5.4 5.4-5.4h5.1c3.067 0 5.4 2.463 5.4 5.4a1 1 0 11-2 0c0-1.863-1.467-3.4-3.4-3.4H9.4zM14.541 5.918a3.35 3.35 0 00-4.618.01c-1.241 1.169-1.233 3.23.043 4.587 1.169 1.241 3.23 1.233 4.587-.043 1.264-1.19 1.3-3.232-.012-4.554zm1.378-1.45a5.35 5.35 0 00-7.366.004c-2.159 2.031-1.967 5.37-.043 7.413 2.031 2.159 5.37 1.967 7.413.043 2.13-2.005 2.1-5.353.026-7.431a3.218 3.218 0 00-.015-.015l-.015-.014z"
        fill="#242938"
      />
    </svg>
  );
}

const MemoIconUserProfile = React.memo(IconUserProfile);
export default MemoIconUserProfile;
