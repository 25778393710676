import * as React from 'react';

function IconInterfaceStarFavorite(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        clipRule="evenodd"
        d="M7.731 20.833A1.5 1.5 0 015.56 19.24l.81-4.639-3.408-3.265a1.5 1.5 0 01.828-2.572l4.73-.676 2.137-4.261a1.5 1.5 0 012.689 0l2.136 4.26 4.73.677a1.5 1.5 0 01.828 2.572l-3.408 3.265.81 4.64a1.5 1.5 0 01-2.172 1.593L12 18.628l-4.269 2.205z"
        stroke="#7C7F88"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconInterfaceStarFavorite = React.memo(IconInterfaceStarFavorite);
export default MemoIconInterfaceStarFavorite;
