import * as React from 'react';

function IconFilledErrorCircle(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M12 3a9 9 0 10.001 18.001A9 9 0 0012 3z" fill="#DE350B" />
      <rect
        x={15.046}
        y={8.368}
        width={1.265}
        height={9.444}
        rx={0.2}
        transform="rotate(45 15.046 8.368)"
        fill="#fff"
      />
      <rect
        x={8.368}
        y={9.263}
        width={1.265}
        height={9.444}
        rx={0.2}
        transform="rotate(-45 8.368 9.263)"
        fill="#fff"
      />
    </svg>
  );
}

const MemoIconFilledErrorCircle = React.memo(IconFilledErrorCircle);
export default MemoIconFilledErrorCircle;
