import * as React from 'react';
import styled from 'styled-components';
import {
  ThreeDotSeparator,
  ThreeDotSeparatorWrapper
} from './ThreeDotSeparator';
import { deviceBreakpoints2 } from '../../utils/hooks/useDeviceQuery.hook';
import { fromScreen } from '../../utils/media-query/responsive.util';

const CenterSectionLayout = styled.div`
  padding: 0 16px;
  margin: 0 auto;

  @media screen and ${deviceBreakpoints2.lg} {
    max-width: 1080px;
    padding: 0px;
  }
`;

const SeparationSectionLayoutWrapper = styled.div`
  ${ThreeDotSeparatorWrapper} {
    margin: 24px 0;
  }

  ${fromScreen(768)} {
    ${ThreeDotSeparatorWrapper} {
      margin: 32px 0;
    }
  }
`;
const isLastChild = (index, total) => index === total - 1;
const shouldHasSeparator = (index, total, lastChildHasSeparator) =>
  (isLastChild(index, total) && lastChildHasSeparator) ||
  !isLastChild(index, total);
export const SeparationSectionLayout = ({
  children,
  className,
  lastChildHasSeparator = true,
  Separator = ThreeDotSeparator
}) => {
  const items = children.filter(item => Boolean(item));
  return (
    <SeparationSectionLayoutWrapper className={className}>
      {items.map((node, index) => (
        <React.Fragment key={index}>
          {node}
          {shouldHasSeparator(index, items.length, lastChildHasSeparator) && (
            <Separator />
          )}
        </React.Fragment>
      ))}
    </SeparationSectionLayoutWrapper>
  );
};

export const SectionLayoutWrapper = styled(CenterSectionLayout)``;
export const SectionLayout = ({
  children,
  lastChildHasSeparator = true,
  ...rest
}) => {
  if (!Array.isArray(children)) {
    return <SectionLayoutWrapper {...rest}>{children}</SectionLayoutWrapper>;
  }

  return (
    <SectionLayoutWrapper {...rest}>
      <SeparationSectionLayout lastChildHasSeparator={lastChildHasSeparator}>
        {children}
      </SeparationSectionLayout>
    </SectionLayoutWrapper>
  );
};
