import 'react-image-lightbox/style.css';

import * as React from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';

import { motion } from 'framer-motion';
import styled from 'styled-components/macro';

import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { getAssetLinkByHandle } from 'utils/getAssetLink';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import Link from './Link';

const Certificates = ({ data, onCertClick = () => {}, ...rest }) => {
  const { title, description, images = [], link } = data;

  return (
    <Container {...rest}>
      <Typography
        style={{ color: colorsV2.white100, marginBottom: 4 }}
        variant="semi-bold/16-24"
      >
        {title}
      </Typography>

      <Typography
        style={{ color: colorsV2.gray40, marginBottom: 16 }}
        variant="regular/14-20"
      >
        {description}
      </Typography>

      <Images images={images} onCertClick={onCertClick} />

      <Typography
        style={{ color: colorsV2.gray40, marginBottom: 4 }}
        variant="medium/14-20"
      >
        {link.description}
      </Typography>

      <BlackThemeLink
        url={link.url}
        title={link.title}
        containerStyle={{
          color: colorsV2.white100
        }}
        typoStyle={{
          color: colorsV2.white100,
          textDecoration: 'underline',
          wordBreak: 'break-all'
        }}
      />
    </Container>
  );
};

const BlackThemeLink = styled(Link)`
  background: ${colorsV2.black80};
  a {
    color: ${colorsV2.white100};
  }
`;

const Container = styled.div`
  padding: 16px;
  background: ${colorsV2.black100};
  border-radius: 12px;

  #popup-root & {
    @media screen and ${deviceBreakpoints.mobile} {
      width: 280px;
    }

    @media screen and ${deviceBreakpoints.tablet},
      ${deviceBreakpoints.desktop} {
      width: 350px;
      img {
        height: 135px;
      }
    }
  }

  @media screen and (min-width: 769px) {
    max-width: 410px;
  }
`;

export const ImageType = PropTypes.shape({
  order: PropTypes.number,
  thumbnail: PropTypes.string,
  original: PropTypes.string.isRequired
});

export const InfoProps = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(ImageType).isRequired,
  link: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
});

Certificates.propTypes = {
  data: InfoProps,
  onCertClick: PropTypes.func
};

export default React.memo(Certificates);

export const Images = ({ images, onCertClick = () => {} }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState(-1);

  React.useEffect(() => {
    if (index > -1) {
      // setOpen(true);
    }
  }, [index]);

  return (
    <CertificatesContainer>
      {images
        .sort((cert1, cert2) => cert1.order - cert2.order)
        .map((cert, idx) => (
          <Thumbnail
            onClick={() => {
              setIndex(idx);
              setOpen(true);
              onCertClick({
                action: 'open'
              });
            }}
            key={cert.order}
            src={getAssetLinkByHandle({ handle: cert.gcmsHandle })}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          />
        ))}

      {isOpen && (
        <Lightbox
          reactModalStyle={{
            overlay: {
              zIndex: 200000
            }
          }}
          mainSrc={getAssetLinkByHandle({ handle: images[index].gcmsHandle })}
          nextSrc={getAssetLinkByHandle({
            handle: images[(index + 1) % images.length].gcmsHandle
          })}
          prevSrc={getAssetLinkByHandle({
            handle:
              images[(index + images.length - 1) % images.length].gcmsHandle
          })}
          onCloseRequest={() => {
            setOpen(false);
            setIndex(-1);
            onCertClick({
              action: 'close'
            });
          }}
          onMovePrevRequest={() =>
            setIndex((index + images.length - 1) % images.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % images.length)}
        />
      )}
    </CertificatesContainer>
  );
};

export const Thumbnail = styled(motion.img)`
  border-radius: 2px;
  width: 100%;
  max-width: 102px !important;

  &:not(:last-child) {
    margin-right: 8px;
  }

  @media ${deviceBreakpoints.md} {
    max-width: 220px !important;
  }
`;

const CertificatesContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  grid-column-gap: 8px;
  margin-bottom: 8px;
  align-items: stretch;
  grid-template-columns: repeat(3, 1fr);
`;

Images.propTypes = {
  images: PropTypes.arrayOf(ImageType),
  onCertClick: PropTypes.func
};
